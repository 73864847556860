import { Link } from 'react-router-dom';
import Areas from '../Areas';
import './Servicos.css';

const Servicos = () => {
    return (
        <div className="servicos">
            <section>
                <h1>Serviços</h1>
            </section>
            <section className="areas">
                <Link to="/auditoria">
                    <Areas servicos="Auditoria" img="./imgs/auditop.png" />
                </Link>
                <Link to="/constituicao">
                    <Areas
                        servicos="Constituição de Empresas"
                        img="./imgs/empresa.png"
                    />
                </Link>
                <Link to="/contabilidade">
                    <Areas
                        servicos="Contabilidade"
                        img="./imgs/contabilidade.png"
                    />
                </Link>
                <Link to="/acessoria-fiscal">
                    <Areas
                        servicos="Acessoria Fiscal"
                        img="./imgs/fiscal.png"
                    />
                </Link>
                <Link to="/rh">
                    <Areas
                        servicos="Departamento Pessoal"
                        img="./imgs/rh.png"
                    />
                </Link>
            </section>
        </div>
    );
};

export default Servicos;
