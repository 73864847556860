import Cabeçalho from '../../Componentes/Cabeçalho';
import Barra from '../../Componentes/Barra';
import Informacoes from '../../Componentes/Informacoes';
import Rodape from '../../Componentes/Rodape';
import Servicos from '../../Componentes/Servicos';

function Home() {
    return (
        <>
            <div>
                <Barra />
                <Cabeçalho backgroundImage="./imgs/Banner.jpg" />
                <section id="nossa-empresa">
                    <Informacoes />
                </section>
                <section id="servicos">
                    <Servicos />
                </section>
                <section id="contato">
                    <Rodape />
                </section>
            </div>
        </>
    );
}

export default Home;
