import './Areas.css';

const Areas = (props) => {
    return (
        <section className="area">
            <ul>
                <li>
                    <img src={props.img} alt={props.alt} />
                    <p>{props.servicos}</p>
                </li>
            </ul>
        </section>
    );
};

export default Areas;
