import './Cabeçalho.css';

const Cabeçalho = (props) => {
    const backgroundImageStyle = props.backgroundImage
        ? {
              background: `url(${props.backgroundImage}) no-repeat center center/cover`,
          }
        : { backgroundColor: '#46494C' };
    return (
        <div className="cabecalho" style={backgroundImageStyle}>
            <h1>{props.h1}</h1>
        </div>
    );
};

export default Cabeçalho;
