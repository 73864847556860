import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { Container } from './style';

const BarraMobile = ({ menuIsVisible, setMenuIsVisible }) => {
    useEffect(() => {
        document.body.style.overflowY = menuIsVisible ? 'hidden' : 'auto';
    }, [menuIsVisible]);

    const handleLinkClick = () => {
        setMenuIsVisible(false);
    };

    return (
        <Container isVisible={menuIsVisible}>
            <IoClose size={36} onClick={() => setMenuIsVisible(false)} />
            <nav className="nav-mobile" id="barra">
                <Link className="link-mobile" to="/" onClick={handleLinkClick}>
                    Home
                </Link>
                <a
                    className="link-mobile"
                    href="https://econtador.alterdata.com.br"
                    onClick={handleLinkClick}
                >
                    Escritorio Virtual
                </a>
                <Link
                    className="link-mobile"
                    to="/#nossa-empresa"
                    onClick={handleLinkClick}
                >
                    Nossa Empresa
                </Link>
                <Link
                    className="link-mobile"
                    to="/#servicos"
                    onClick={handleLinkClick}
                >
                    Serviços
                </Link>
                <Link
                    className="link-mobile"
                    to="/#contato"
                    onClick={handleLinkClick}
                >
                    Contato
                </Link>
            </nav>
        </Container>
    );
};

export default BarraMobile;
