import { useState } from 'react';
import BarraDesktop from '../BarraDesktop';
import BarraMobile from '../BarraMobile';

const Barra = () => {
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    return (
        <>
            <BarraMobile
                menuIsVisible={menuIsVisible}
                setMenuIsVisible={setMenuIsVisible}
            />
            <BarraDesktop setMenuIsVisible={setMenuIsVisible} />
        </>
    );
};

export default Barra;
