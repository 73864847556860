import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BarraDesktop.css';
import ScrollToTop from '../../ScrollToTop';
import { RiMenu3Fill } from 'react-icons/ri';

const BarraDesktop = ({ setMenuIsVisible }) => {
    const [barra, setBarra] = useState(false);
    const location = useLocation();

    const mudancaBackground = () => {
        if (window.scrollY >= 80) {
            setBarra(true);
        } else {
            setBarra(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', mudancaBackground);
        return () => {
            window.removeEventListener('scroll', mudancaBackground);
        };
    }, []);

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <nav className={barra ? 'barra ativa' : 'barra'} id="barra">
            <Link to="/" onClick={ScrollToTop}>
                <img src="./imgs/Logo.png" alt="Imagem de logo da página" />
            </Link>
            <section className="navbar-desktop">
                <ul>
                    <li>
                        <Link to="/" onClick={ScrollToTop}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <a href="https://econtador.alterdata.com.br">
                            Escritorio Virtual
                        </a>
                    </li>
                    <li>
                        <Link to="/#nossa-empresa">Nossa Empresa</Link>
                    </li>
                    <li>
                        <Link to="/#servicos">Serviços</Link>
                    </li>
                    <li>
                        <Link to="/#contato">Contato</Link>
                    </li>
                </ul>
            </section>
            <RiMenu3Fill
                onClick={() => {
                    setMenuIsVisible(true);
                    window.scrollTo(0, 0);
                }}
                className="mobile"
            />
        </nav>
    );
};

export default BarraDesktop;
