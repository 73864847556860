import BarraDesktop from '../../Componentes/Barra';
import Rodape from '../../Componentes/Rodape';
import Cabeçalho from '../../Componentes/Cabeçalho';
import Corpo from '../../Componentes/Corpo';

export default function Contabilidade() {
    return (
        <div>
            <BarraDesktop />
            <Cabeçalho
                backgroundImage="./imgs/contabilidade-background.jpg"
                h1="Contabilidade"
            />
            <Corpo
                cite='"É o processo meticuloso de registro e análise das transações financeiras, garantindo precisão, transparência e integridade para a tomada de decisões econômicas informadas."'
                p={[
                    'Através do profundo conhecimento do sistema tributário brasileiro e leis que regem o setor, a AUDITOP cumpre desde as rotinas mais comuns da contabilidade à elaboração de estudos e relatórios que permitam minimizar a carga tributária da empresa.',
                    'Somos especializados em <strong>contabilidade comercial e industrial, destacando CONDOMÍNIOS, ENTIDADES FILANTRÓPICAS (Igrejas), SINDICATOS  e CONSTRUTORAS.</strong>',
                ]}
            />
            <Corpo
                h3="Dentro os serviços prestados damos enfase a esses tópicos:"
                li={[
                    'Escrituração contábil',
                    'Emissão de balancetes mensais e periódicos',
                    'Balanço patrimonial e demonstrações contábeis semestrais ou anuais',
                    'Elaboração de relatórios gerenciais de desempenho',
                    'Controle e Administração dos bens do Ativo Fixo (imobilizado)',
                    'Contabilidade Digital-SPED–ECD–EFD–FCONT',
                    'Parametrizaçoes Contábeis e Fiscais',
                ]}
            />
            <Rodape />
        </div>
    );
}
