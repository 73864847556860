import { useState } from 'react';
import Botao from '../Botao';
import CampoTexto from '../CampoTexto';
import './Rodape.css';

const Rodape = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [nomeError, setNomeError] = useState('');

    const validarForm = () => {
        if (nome === '') {
            setNomeError('Campo obrigatório');
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await fetch(
            'https://send-email-jade.vercel.app/api/send-email',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: nome,
                    email: email,
                    subject: assunto,
                    message: mensagem,
                }),
            }
        );

        if (response.ok) {
            alert('Email enviado com sucesso');
            setNome('');
            setEmail('');
            setAssunto('');
            setMensagem('');
        } else {
            alert('Erro ao enviar email. Tente novamente');
        }
    };

    return (
        <footer className="rodape">
            <section>
                <h2>Contato</h2>
                <h3> Telefones:</h3>
                <p>(41)99644-2222</p>
                <p>(41)3019-9998</p>
                <h3>Endereço</h3>
                <p>
                    Rua Conselheiro Laurindo 715, 1 Andar - Centro - Curitiba PR
                </p>
            </section>
            <section>
                <form>
                    <CampoTexto
                        obrigatorio={true}
                        label="Nome *"
                        placeholder="Digite seu nome"
                        valor={nome}
                        onChange={(e) => {
                            setNome(e.target.value);
                        }}
                    />

                    <CampoTexto
                        obrigatorio={true}
                        label="Email *"
                        placeholder="Digite seu email"
                        valor={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <CampoTexto
                        label="Assunto"
                        placeholder="Digite o assunto"
                        valor={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                    />
                    <CampoTexto
                        obrigatorio={true}
                        label="Mensagem *"
                        placeholder="Digite a mensagem"
                        valor={mensagem}
                        onChange={(e) => setMensagem(e.target.value)}
                    />
                    <Botao
                        onClick={() => validarForm() && handleSubmit()}
                        children="Enviar"
                    />
                    <div>{nomeError !== '' && <div>{nomeError}</div>}</div>
                </form>
            </section>
        </footer>
    );
};

export default Rodape;
