import BarraDesktop from '../../Componentes/Barra';
import Rodape from '../../Componentes/Rodape';
import Cabeçalho from '../../Componentes/Cabeçalho';
import Corpo from '../../Componentes/Corpo';

export default function Auditoria() {
    return (
        <div>
            <BarraDesktop />
            <Cabeçalho
                backgroundImage="./imgs/auditoria-background.jpg"
                h1="Auditoria"
            />
            <Corpo
                cite='"É a análise e avaliação das demonstrações contábeis e dos controles organizacionais, buscando adequação de procedimentos, clareza, segurança e economia."'
                p={[
                    'Com esta ferramenta de vital importância para a manutenção e aumento da rentabilidade através de análise profissionalde normas, rotinas, atividades, demonstrações contábeis, operações e controles internos das empresas e com um método de trabalho adequado à realidade de cada cliente, a AUDITOP pode obter, em um curto espaço de tempo, informações estratégicas a nossos clientes, visando assim oferecer informação com qualidade e objetividade para uma tomada de decisão mais segura.',
                    'Nosso escritório tem especialidade em todo tipo de auditoria contabil, incluindo auditoria de condomínios, entidades entre outros.',
                ]}
            />
            <Corpo
                h3="Demonstrações contábeis sujeitas à auditoria contábil: "
                li={[
                    'Balanço Patrimonial',
                    'Demonstração do Resultado do Exercício',
                    'Demonstração das Mutações do Patrimônio Líquido',
                    'Demonstração do Fluxo de Caixa',
                    'Notas Explicativas às Demonstrações Contábeis',
                ]}
            />
            <Corpo
                h3="Auditoria Operacional itens a serem auditados:"
                li={[
                    'Compras e Contratações',
                    'Custódia de Ativos',
                    'Estoques',
                    'Área de Pessoal',
                    'Processamento de Dados',
                    'Qualidade e Meio Ambiente',
                    'Entre Outros',
                ]}
            />
            <Corpo
                h3="Revisão de Fluxos Operacionais:"
                li={[
                    'Compras e Pagamentos',
                    'Vendas e Recebimentos',
                    'Orçamentos',
                    'Tesouraria',
                    'Caixas e Bancos',
                    'Estoques',
                    'Despesas',
                    'Imobilizado',
                    'Departamento Pessoal',
                    'Planejamento e Controle da Produção',
                ]}
            />
            <Rodape />
        </div>
    );
}
