import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Paginas/Home';
import Auditoria from './Paginas/Auditoria';
import ScrollToTop from './ScrollToTop';
import AcessoriaFiscal from './Paginas/Acessoria-Fiscal';
import Contabilidade from './Paginas/Contabilidade';
import Rh from './Paginas/Rh';

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/auditoria" element={<Auditoria />}></Route>
                <Route
                    path="/acessoria-fiscal"
                    element={<AcessoriaFiscal />}
                ></Route>
                <Route
                    path="/contabilidade"
                    element={<Contabilidade />}
                ></Route>
                <Route path="/rh" element={<Rh />}></Route>
            </Routes>
        </BrowserRouter>
    );
}
