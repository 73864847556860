import Barra from '../../Componentes/Barra';
import Rodape from '../../Componentes/Rodape';
import Cabeçalho from '../../Componentes/Cabeçalho';
import Corpo from '../../Componentes/Corpo';

export default function Rh() {
    return (
        <>
            <div>
                <Barra />
                <Cabeçalho
                    backgroundImage="./imgs/rh-background.jpg"
                    h1="Departamento Pessoal"
                />
                <Corpo
                    cite='"É a gestão e supervisão das relações trabalhistas e dos processos de recursos humanos, assegurando conformidade legal, clareza nas comunicações, segurança no ambiente de trabalho e eficiência operacional."'
                    p={[
                        '<strong>O processamento mensal da folha de pagamento é uma das mais complexas rotinas administrativas que todas as empresas realizam. Além disso, pode se tornar uma fonte de diversos problemas, principalmente devido à complexidade da legislação trabalhista.</strong>',
                        '<strong>A terceirização é uma estratégia que tem sido cada vez mais adotada em todo o mundo.</strong>',
                    ]}
                />
                <Corpo
                    h3="Nossos serviços contemplam:"
                    li={[
                        'Geração da folha de pagamento, impostos, declarações mensais e anuais (CAGED, RAIS, SEFIP)',
                        'Admissão, demissão, férias, fechamento de horas extras',
                        'Administração do controle de compensação de horário dos colaboradores',
                        'Planilha de controles de: férias, contrato de experiência, controle de exames, aniversários e outros conforme a necessidade da empresa',
                        'Identificação de aspectos relevantes quanto à melhor forma para se economizar dinheiro com administração dos colaboradores',
                        'Orientação para elaboração da política de recursos humanos',
                        'Relatórios de custo por departamento e por funcionário, incluindo todos os impostos e benefícios do empregado',
                        'Enquadramento Sindical',
                        'Controle de Férias',
                        'Acompanhamento de Convenção Coletiva',
                        '13º Salário',
                        'Orientação sobre legislação trabalhista (CLT)',
                        'Orientação sobre benefícios',
                        'E-SOCIAL',
                    ]}
                />

                <Rodape />
            </div>
        </>
    );
}
