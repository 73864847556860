import Barra from '../../Componentes/Barra';
import Rodape from '../../Componentes/Rodape';
import Cabeçalho from '../../Componentes/Cabeçalho';
import Corpo from '../../Componentes/Corpo';

export default function AcessoriaFiscal() {
    return (
        <div>
            <Barra />
            <Cabeçalho
                backgroundImage="./imgs/acessoria-fiscal-background.jpg"
                h1="Acessoria Fiscal"
            />
            <Corpo
                cite='"É o exame detalhado e sistemático das obrigações tributárias e estratégias fiscais, visando a conformidade legal, transparência, proteção e eficiência fiscal."'
                p={[
                    'A AUDITOP está plenamente capacitada a auxiliar empresas na sua buscapor maior Competitividade. Neste segmento, nossa experiência em processos, e a larga vivência de mercado permitem criar uma metodologia produtiva, voltada inclusive para a otimização de custos.',
                    'Os resultados e considerações são apresentados na forma de Manuais de Procedimento e Organização.',
                ]}
            />
            <Corpo
                h3="Trabalhando em áreas específicas sendo:"
                li={[
                    'Consultoria-Financeira',
                    'Organizacional',
                    'Societária',
                    'Fiscal/Tributária',
                    'Assessoria Contábil',
                ]}
            />
            <Corpo
                h3="Consultoria Organizacional:"
                li={[
                    'Análise de Funções e Reavaliação do Organograma',
                    'Reestruturação Societária',
                    'Avaliação do Sistema de Informações',
                    'Entre Outros',
                ]}
            />
            <Corpo
                h3="Consultoria Operacional:"
                li={[
                    'Análise de Procedimentos Administrativos e Financeiros',
                    'Melhoria de Processos como Compras, Vendas, RH, Materiais, dentre outros',
                ]}
            />
            <Corpo
                h3="Fiscal/Tributária:"
                li={[
                    'A Revisão',
                    'Possibilidades de recuperação ou compensação conforme pagamento a maior ou indevido tendo como abrangência: PIS, COFINS, IRPJ, CSSL, ISS, ICMS, Entre Outros',
                    'Revisão de Procedimentos Administrativos',
                    'Revisão das obrigações acessórias',
                    'Análise da Melhor Alternativa Tributária: esta análise proporciona reavaliar a tributação empresarial, sugerindo novas alternativas menos onerosas, sempre considerando os aspectos da legalidade e não incidência em contingências fiscais',
                ]}
            />
            <Corpo
                h3P="Assessoria Contábil:"
                p={[
                    'Utilizando dos relatórios emitidos pelo setor contábil, damos total suporte ao entendimento deles, e procuramos através das informações prestadas analizarmos como esta a saúde financeira de sua organização, utilizando de Índices de Análises de Balanços, fornecendo assim uma resposta clara e abrangente sobre o desempenho da organização',
                ]}
            />
            <Rodape />
        </div>
    );
}
