import './Informacoes.css';

const Informacoes = () => {
    return (
        <div className="titulo">
            <h1>Nossa Empresa</h1>
            <div className="informacoes">
                <section className="a">
                    <h2>Sobre Nós:</h2>
                    <p>
                        A Auditop Auditoria e Contabilidade, fundada por Rupélio
                        Colferai, onde traz em sua bagagem uma experiência desde
                        1976 na área de contabilidade, oferecendo serviços de
                        Auditoria, Contabilidade e Assessoria Contábil.
                    </p>
                </section>
                <section className="b">
                    <h2>Nossa Missão:</h2>
                    <p>
                        Fornecer aos Clientes serviços de Auditoria,
                        Contabilidade e Assessoria a fim de auxiliar na gestão
                        organizacional da empresa corroborando para a
                        multiplicação dos Resultados de seus Clientes e
                        munido-os, através da lei, a fim de evitar passivos
                        ocultos futuros.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default Informacoes;
