import './CampoTexto.css';

const CampoTexto = (props) => {
    const placeholderModificada = `${props.placeholder}...`;

    return (
        <div className="campo-texto">
            <label>{props.label}</label>
            <input
                value={props.valor}
                required={props.obrigatorio}
                placeholder={placeholderModificada}
                onChange={props.onChange}
            />
        </div>
    );
};

export default CampoTexto;
