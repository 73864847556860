import styled, { css } from 'styled-components';

export const Container = styled.section`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    background: rgb(34, 34, 34);
    background: linear-gradient(
        360deg,
        rgba(34, 34, 34, 1) 0%,
        rgba(153, 153, 153, 1) 100%
    );

    transform: translateY(50px);
    transition: 0.5s;

    > svg {
        position: absolute;
        top: 1rem;
        right: 1rem;
        transform: rotate(45deg);
        transition: 0.7s;
    }

    .nav-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        transform: scale(0.7);
        transition: 0.7s;
    }

    .link-mobile {
        text-decoration: none;
        color: #fff;
        transition: color 0.3s ease;
    }

    .link-mobile:hover {
        color: rgb(255, 80, 16);
    }

    ${({ isVisible }) =>
        isVisible &&
        css`
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0px);

            > svg {
                transform: rotate(360deg);
            }

            .nav-mobile {
                transform: scale(1);
            }
        `}
`;
