import React from 'react';
import './Botao.css';

const Botao = ({ children, onClick, disabled }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onClick(e);
    };

    return (
        <button className="botao" onClick={handleClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Botao;
