import './Corpo.css';

const Corpo = (props) => {
    return (
        <div className="Corpo">
            <cite className="cite">{props.cite}</cite>

            <h3>{props.h3P}</h3>

            {props.p &&
                props.p.map((item, index) => (
                    <Paragraph key={index} text={item} />
                ))}

            <h3>{props.h3}</h3>
            <ul>
                {props.li &&
                    props.li.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
        </div>
    );
};

const Paragraph = ({ text }) => {
    const parts = text.split(/(<strong>.*?<\/strong>)/g);

    return (
        <p>
            {parts.map((part, index) =>
                part.includes('<strong>') ? (
                    <strong key={index}>
                        {part.replace(/<\/?strong>/g, '')}
                    </strong>
                ) : (
                    part
                )
            )}
        </p>
    );
};

export default Corpo;
